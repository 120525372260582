import React from 'react'
import { graphql } from 'gatsby'
import PageWrapper from '../components/pageWrapper'
import CardSmall from '../components/cardSmall'
import PageDescription from '../components/pageDescription'
import HeroImage from '../components/heroImage'
import Footer from '../components/footer'

const TeamPage = ({ data: { datoCmsTeamPage: { name, description, heroImage, teamMembers } } }) => {
  return (
    <>
      <HeroImage media={heroImage}/>
      <PageWrapper>
        <PageDescription
          title={name}
          text={description}
        />
        {teamMembers.map(({ name, media, about, originalId }) => (
          <CardSmall
            key={originalId}
            title={name}
            text={about}
            media={media}
          />
        ))}
      </PageWrapper>
      <Footer/>
    </>
  )
}

export const query = graphql`
  query TeamPage {
    datoCmsTeamPage {
      name
      description
      heroImage {
        alt
        fluid(maxWidth: 1400, maxHeight: 630, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      teamMembers {
        name
        about
        originalId
        media {
          id
          alt
          fluid(maxWidth: 400, maxHeight: 400, imgixParams: {
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "faces"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`

export default TeamPage
